/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Image from "gatsby-image";

import { rhythm } from "../utils/typography";

import TechStack from "./tech-stack";
import Publications from "./publications";

const FullBio = () => {
  const data = useStaticQuery(graphql`
    query FullBioQuery {
      avatar: file(absolutePath: { regex: "/profile_pic.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      allFile(filter: { relativeDirectory: { eq: "logos" } }) {
        edges {
          node {
            id
            name
            base
            childImageSharp {
              fixed(width: 40, height: 40) {
                ...GatsbyImageSharpFixed
              }
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            linkedin
            github
            codesignal
          }
        }
      }
    }
  `);

  const { author, social } = data.site.siteMetadata;

  const socialLinksToDisplay = ["GitHub", "LinkedIn", "CodeSignal"];

  const socialLinksComponent = socialLinksToDisplay.map(socialLinkName => (
    <a
      className="no-decoration"
      target="_blank"
      rel="noopener noreferrer"
      href={social[socialLinkName.toLowerCase()]}
    >
      <Image
        className="is-grayscale"
        fixed={
          data.allFile.edges.filter(
            edge => edge.node.name === socialLinkName.toLowerCase()
          )[0].node.childImageSharp.fixed
        }
        alt={socialLinkName}
        title={socialLinkName}
        style={{
          marginRight: rhythm(1 / 2),
          marginBottom: 0
        }}
        imgStyle={{}}
      />
    </a>
  ));

  return (
    <div>
      <h1 style={{ textAlign: `center` }}>About Me</h1>
      <Image
        fluid={data.avatar.childImageSharp.fluid}
        alt={author}
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          marginBottom: 0,
          width: `35%`,
          borderRadius: `100%`
        }}
        imgStyle={{
          borderRadius: `50%`
        }}
      />
      <div
        style={{
          display: `flex`,
          justifyContent: `center`,
          marginTop: `1.5em`
        }}
      >
        {socialLinksComponent}
      </div>
      <div style={{ marginTop: `2.5em` }}>
        <p>
          I'm a Software Developer from Brazil currently living in Germany and working as a Cloud Engineer,
          using mostly <strong>AWS</strong>, <strong>Terraform</strong>,{" "}
          <strong>Kubernetes</strong>, <strong>Java</strong>, <strong>TypeScript</strong> and <strong>Angular</strong>.
        </p>
        <p>
          I'm a versatile developer and have experience working in projects for
          companies such as Lenovo, Motorola and Dell using a different range of
          technologies: from <strong>low-level C++</strong> accessing hardware
          devices to <strong>scalable web applications</strong> built with{" "}
          <strong>Angular</strong>,<strong> React</strong> and{" "}
          <strong>Google Cloud Platform</strong>. I enjoy writing{" "}
          <strong>clean, testable code</strong> and love learning new things.
        </p>
        <p>
          I graduated in <strong>Computer Science</strong> at Federal University
          of Ceará (UFC) and have a <strong>Master’s degree</strong> from
          Federal University of Rio Grande do Sul (UFRGS).
        </p>
        <p>
          My Master's general area was in <strong>Computer Vision</strong> and{" "}
          <strong>Image Processing</strong> and my research topic was vehicle
          detection in urban traffic videos and shadow detection.
        </p>
      </div>
    </div>
  );
};

export default FullBio;
